
// @ts-nocheck
import locale__app_lang_es_ES_json from "../lang/es-ES.json";
import locale__app_lang_pt_PT_json from "../lang/pt-PT.json";


export const localeCodes =  [
  "es",
  "pt"
]

export const localeLoaders = {
  "es": [{ key: "../lang/es-ES.json", load: () => Promise.resolve(locale__app_lang_es_ES_json), cache: true }],
  "pt": [{ key: "../lang/pt-PT.json", load: () => Promise.resolve(locale__app_lang_pt_PT_json), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "es",
      "name": "ES",
      "domain": "familycard.es",
      "files": [
        "/app/lang/es-ES.json"
      ]
    },
    {
      "code": "pt",
      "name": "PT",
      "domain": "familycard.pt",
      "files": [
        "/app/lang/pt-PT.json"
      ]
    }
  ],
  "defaultLocale": "es",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "lang",
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "promocion": {
      "es": "/legal/condiciones-promocionales",
      "pt": "/legal/condicoes-promocionais"
    },
    "family_card": {
      "es": "/legal/reglamento-family-card",
      "pt": "/legal/regulamento-family-card"
    },
    "family_card_vip": {
      "es": "/legal/reglamento-family-card-vip",
      "pt": "/legal/regulamento-family-card-vip"
    },
    "register": {
      "es": "/hazte-socio",
      "pt": "/torna-te-membro"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "es",
    "name": "ES",
    "domain": "familycard.es",
    "files": [
      {
        "path": "/app/lang/es-ES.json"
      }
    ]
  },
  {
    "code": "pt",
    "name": "PT",
    "domain": "familycard.pt",
    "files": [
      {
        "path": "/app/lang/pt-PT.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
